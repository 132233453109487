.navbar {
  position: fixed;
  padding: 5rem 1rem;
  width: 20vw;
  max-width: 20rem;
  height: 100vh;
  background-color: rgb(33, 37, 41);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.navItem {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 7px;
  transition-duration: 0.1s;
  color: #ccc;
  margin-bottom: 3rem; 
  font-size: 2rem;
  max-width: 100%;
  text-decoration: none;
}

@media screen and (max-width: 1100px) {
  .navItem {
    font-size: 1rem;
  }
}

.navItem:hover {
  /* background of navbar */
  /* background-color: rgb(33, 37, 41); */
  background-color: rgb(43, 47, 51);
  color: #fff;
}

.navItem:active {
  /* background-color: aqua; */
  border-right: 3px solid #01bf71;
}