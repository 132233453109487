.skills {
  list-style: none;
  padding: 1em 0 1em 0;
}

.skill {
  display: inline-block;
  background: #eee;
  border-radius: 999em;
  padding: 0.2em 0.7em;
  margin: 0 0.5em 0.5em 0;
}

.top {
  background: rgb(33, 37, 41);
  color: white;
}